import './Features.css';
import { useState } from 'react';

import { feature1,feature2,feature3,feature4,feature5, feature6} from '../../../assets/features';

import {TvIcon,CollectionIcon,GalleryIcon,DashBoardIcon,EditIcon,PeopleIcon } from '../../../assets/icons/';

const features = [
  { 'icon': GalleryIcon, 'image': feature1, 'title': "High quality image support", 'description':  <span>Explore featured, trending and curated<br/>art collections. Save and organize favorite<br/>artworks and artists</span> },
  { 'icon': CollectionIcon, 'image': feature2, 'title': "Curated collections", 'description': <span>Explore featured, trending and curated<br/>art collections. Save and organize favorite<br/>artworks and artists</span> },
  { 'icon': PeopleIcon, 'image': feature3, 'title': "Community engagement", 'description': <span>Explore featured, trending and curated<br/>art collections. Save and organize favorite<br/>artworks and artists</span> },
  { 'icon': TvIcon, 'image': feature4, 'title': "Easy streaming", 'description': <span>Explore featured, trending and curated<br/>art collections. Save and organize favorite<br/>artworks and artists</span> },
  { 'icon': DashBoardIcon, 'image': feature5, 'title': "Artist dashboard", 'description': <span>Explore featured, trending and curated<br/>art collections. Save and organize favorite<br/>artworks and artists</span> },
  { 'icon': EditIcon, 'image': feature6, 'title': "Consumer personalization", 'description': <span>Explore featured, trending and curated<br/>art collections. Save and organize favorite<br/>artworks and artists</span> },
];
function Features() {
  const [featureIndex, setFeatureIndex] = useState(0);

  
  return (
    <div id="features" className="features__section">
      <p className='features__section__title'>Our Features & Benefits</p>

      <ResponsiveFeatureCards/>
      <div className="features">
        <div className="features__left">
          {features.map((feature, index) => {
            const isActive = index === featureIndex;


          const FeatureIcon=feature.icon;
            return (
              <div
                key={index}
                className={`feature__card ${isActive ? 'feature__card__enable' : 'feature__card__disable'}`}
                onClick={() => setFeatureIndex(index)}
              >
                <FeatureIcon className={isActive ? 'feature__icon__enable' : 'feature__icon__disable'}/>
          
               
              
                <div>
                  <div className={isActive ? 'feature__title__enable' : 'feature__title__disable'}>
                    {feature.title}
                  </div>
                  {isActive && (
                    <div className="feature__description__enable">
                      {feature.description}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
        <div className="features__right"><img   src={features[featureIndex].image} alt='feature'  className="features__right_image"></img></div>
        
      </div>

      
      
    </div>
  );
}




const ResponsiveFeatureCards=()=>{


  return (<div className='responsive-feature-cards'>


    {features.map((feature,index )=>{

const FeatureIcon=feature.icon;
      return(< div  key={index}  className='responsive-feature-cards'>
       <img   src={feature.image} className="responsive-feature-card__image"></img>
       <div className='responsive-feature-cards_content'> 


       <FeatureIcon className='responsive-feature-cards_content__left' />
  
        <div className='responsive-feature-cards_content__right'>
          <h3 className='responsive-feature-cards_content__title'>{feature.title}</h3>
          <p className='responsive-feature-cards_content__description'>{feature.description}</p>
        </div>
       </div>
      
      </div>)
    })}
  </div>);
}

export default Features;



