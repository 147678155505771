import './HomeGuide.css'

import {artistGuide1,artistGuide2,artistGuide3 } from '../../../assets/artist/index'


import  { consumerGuide1,consumerGuide2,consumerGuide3} from  '../../../assets/consumer/consumer_guide/index'
import { useState } from 'react';
function HomeGuide(){

    const [isConsumer,setIsConsumer]=useState(true);
    
    const consumerGuide=[
        {

        'title':"Browse and discover",
        'description':"Explore featured, trending and curated art collections.",
        'image':consumerGuide1,
        'borderColor':"#FF8E3C"
    },
        {

        'title':"Select your favorite",
        'image':consumerGuide2
        ,'description':"Save and organise favorite artworks and artists.",'borderColor':"#D8A53E"
    },
        {

        'title':"Stream to TV",
        'image':consumerGuide3,'description':"Seamlessly stream chosen art directly to your TV or Other devices.",'borderColor':"#5CAA3B"
    },
];
    const artistGuide=[
        {
        'title':"Upload your art",
        'image':artistGuide1,'description':"Easily upload high-resolution images.",
        'borderColor':"#FF8E3C"
    },
        {

        'title':"Describe and categorize",
        'image':artistGuide2,'description':"Add descriptions, tags, and categories for easy discovery. ",'borderColor':"#D8A53E"
    },
        {

        'title':"Publish and share",
        'image':artistGuide3,'description':"Display your portfolio on PiXLMe, accessible to a wide audience.",'borderColor':"#5CAA3B"
    },
];
    
    
const renderGuideSteps = (guideList) => {
    return guideList.map((guide, index) => (
      <div className="stepguide" key={index}>
        <div className="stepguide__header">
          <div className="stepguide__header__count" >{index + 1}</div>
          <p className="role-stepguide__header__title"   style={ {color:guide.borderColor} }>{guide.title}</p>
        </div>

        <div className='role-stepguide__header__content' > <img  src={guide.image} alt={guide.title} className="stepguide__card"   >
     
     </img>
     <div className="role-stepguide__description">{guide.description}</div></div>
       
      </div>
    ));
  };




    return (
    <div  className="homeguide__section">

<div className="homeguide__header">
<p className="homeguide__header__title">Step by step guide</p>
<div className="homeguide__header__button"> 
<div className={`${isConsumer ? "homeguide__header__button_enable" : "homeguide__header__button_disabled"}`} onClick={()=>{
    setIsConsumer(true);
}} >For Consumers</div>
<div className={`${!isConsumer ? "homeguide__header__button_enable" : "homeguide__header__button_disabled"}`} onClick={()=>{
    setIsConsumer(false);
}}>For Artist</div>

</div>


</div>

    <div className="homeguide__body">
    {   isConsumer ? renderGuideSteps(consumerGuide) : renderGuideSteps(artistGuide)}
        </div>


{/* <div className='trybutton' >Try Now</div> */}

    </div>);
}


export default  HomeGuide;