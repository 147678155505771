import React, { useState, useMemo } from 'react';
import './Testimonials.css';
import { CgChevronLeft, CgChevronRight } from "react-icons/cg";
import artistGuide from '../../assets/artist/artistGuide1.svg';


function Testimonials({user}) {
    const testimonials = useMemo(() => [
        {
            name: "Ritwik Muralidar",
            description: "You guys have amazing customer service with such a quick response time. I appreciate all the assistance!",
            date: "23 Jun 2024",
            image: artistGuide,
        },
        {
            name: "John Doe",
            description: "You guys have amazing customer service with such a quick response time. I appreciate all the assistance!",
            date: "15 Jul 2024",
            image: artistGuide,
        },
        {
            name: "Jane Smith",
            description: "You guys have amazing customer service with such a quick response time. I appreciate all the assistance!",
            date: "30 Aug 2024",
            image: artistGuide,
        }
    ], []);

    const [currentIndex, setCurrentIndex] = useState(0);

    const goToNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
    };

    const goToPrevious = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length);
    };

    const goToSlide = (index) => {
        setCurrentIndex(index);
    };

    return (
        <div id='artists' className="testimonials__section">
            <p className='testimonials__section__title'>What our {user} say</p>

            <div className='testimonials__carousel'>
                <div className='testimonials__card'>
                        {/* <div className='testimonials__card__left'>
                            <img src={doublequotes} className='testimonials__card__doublequote' alt="double quotes" />
                            <h2 className='testimonials__card__title'>{testimonials[currentIndex].name}</h2>
                            <p className='testimonials__card__description'>{testimonials[currentIndex].description}</p>
                            <p className='testimonials__card__time'>{testimonials[currentIndex].date}</p>
                        </div>
                        <div className='testimonials__card__right'>
                            <img src={testimonials[currentIndex].image} className='testimonials__card__image' alt="artist guide" />
                        </div> */}

                        <h1 className='testimonials-coming-soon'>Coming soon</h1>
                </div>
            </div>

            <div className="custom-controls">
                <div className="custom-arrows">
                    <CgChevronLeft onClick={goToPrevious} className="custom-arrow custom-arrow--prev" />
                    <div className="custom-dots">
                        {testimonials.map((_, index) => (
                            <span
                                key={index}
                                className={`custom-dot ${index === currentIndex ? 'active' : ''}`}
                                onClick={() => goToSlide(index)}
                            ></span>
                        ))}
                    </div>
                    <CgChevronRight onClick={goToNext} className="custom-arrow custom-arrow--next" />
                </div>
            </div>
        </div>
    );
}

export default Testimonials;
