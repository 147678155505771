import './HomeMainSection.css';
import React from 'react';


const HomeMainSection = () => {

   
  return  (
        <div className='homeMainSection'>
      

            <div className='getStartedBox'>
              <p className='home-getStartedBox__title'>
                Discover and stream<br />stunning Art on your TV
              </p>
              <p className='home-getStartedBox__subtitle'>
              Enjoy beautiful art from talented creators on your favourite screens.
              </p>
              <div className='getStartedBox__button'>
                <p>Get Started</p>
              </div>
            </div>
      
         
        </div>
      )
};

export default HomeMainSection;
